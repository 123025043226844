import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import Layout from '../components/_global/Layout'
import { down, between } from 'styled-breakpoints'
import SEO from '../components/_global/SEO'
import { Container } from '../components/shared/Containers'
import { graphql } from 'gatsby'
import * as Popover from '@radix-ui/react-popover'

const Section = styled.section`
  padding-top: 50px;
  padding-bottom: 50px;

  ${down('mdmax')} {
    padding-top: 25px;
    padding-bottom: 25px;
  }
`

const HeroSectionWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  margin: 0 100px;
  z-index: 15;

  ${down('lgmax')} {
    margin: 0 25px;
  }

  ${down('mdmax')} {
    margin: 0;
    padding: 0 1rem;
    flex-direction: column;
    align-items: center;
  }
`

const HeroSectionImage = styled.div`
  position: relative;
  max-width: 550px;
  width: 100%;
  bottom: -50px;

  ${down('mdmax')} {
    bottom: -25px;
  }
`

const HeroSectionContent = styled.div`
  padding-left: 40px;

  ${down('mdmax')} {
    padding: 0;
  }
`

const HeroSectionContentIcons = styled.div`
  max-width: 200px;
  margin-bottom: 10px;

  ${down('mdmax')} {
    margin: 0 auto;
  }
`

const HeroSectionContentTitle = styled.h1`
  font-size: 64px;
  line-height: 1.1;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;

  & > span {
    display: block;
    font-size: 24px;
    line-height: inherit;
  }

  ${down('xlmax')} {
    font-size: 36px;

    & > span {
      font-size: 18px;
    }
  }

  ${down('mdmax')} {
    text-align: center;
  }
`

const HeroSectionContentButtons = styled.div`
  margin-top: 25px;
  display: flex;
  gap: 15px;

  ${down('mdmax')} {
    flex-direction: column;
  }
`

const HeroButton = styled.a`
  all: unset;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.25rem;
  text-transform: uppercase;
  max-width: 500px;
  min-width: 125px;
  padding: 10px 15px;
  border-radius: 15px;
  background-color: #7cb227;
  color: #fff;

  ${down('mdmax')} {
    padding: 5px 10px;
  }
`

const HeroButtonRounded = styled(HeroButton)`
  position: relative;
  border-radius: 9999px;
  background-color: #add4e1;
  min-width: 200px;

  & > span {
    width: 51px;
    height: 51px;
    background-color: #fff;
    border-radius: 9999px;
    position: absolute;
    top: 0;
    left: 0;

    ${down('mdmax')} {
      width: 41px;
      height: 41px;
    }

    &:after {
      content: '';
      width: 47px;
      height: 47px;
      border-radius: 9999px;
      background-color: #7cb227;
      position: absolute;
      top: 2px;
      left: 2px;

      ${down('mdmax')} {
        width: 37px;
        height: 37px;
      }
    }
  }
`

const BlueSection = styled(Section)`
  position: relative;
  text-align: center;
  background-color: #add4e1;
  color: #fff;
`

const GreenSection = styled(Section)`
  text-align: center;
  background-color: #7cb227;
  color: #fff;
`

const ContainerSmall = styled.div`
  max-width: 850px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
`

const HeartImage = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(0%, 35%);
`

const TextCenter = styled.div`
  text-align: center;
`

const DotWrapper = styled.div`
  position: relative;
`

const FirstImageWrapper = styled.div`
  position: relative;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
`

const SecondImageWrapper = styled.div`
  position: relative;
  max-width: 750px;
  margin-left: 5%;

  ${down('mdmax')} {
    margin-left: 0;
  }
`

const ThirdImageWrapper = styled.div`
  position: relative;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
`

const PopupButton = styled.button<{ color: string }>`
  all: unset;
  border-radius: 9999px;
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  padding: 8px 12px;
  border: 4px solid ${(props) => props.color};
  background-color: #fff;

  ${down('mdmax')} {
    padding: 5px;
    border-width: 2px;
  }

  & > svg {
    width: 30px;
    height: auto;

    ${down('mdmax')} {
      width: 20px;
    }
  }

  & > span {
    ${down('mdmax')} {
      display: none;
    }
  }
`

const StyledPopoverContent = styled(Popover.Content)<{ color: string }>`
  width: 325px;
  background-color: #fff;
  border: 4px solid ${(props) => props.color};
  border-radius: 40px;
  padding: 15px;
`

const PopupButtonFirst = styled(PopupButton)`
  position: absolute;
  top: 18%;
  left: 73%;
`

const PopupButtonSecond = styled(PopupButton)`
  position: absolute;
  top: 28%;
  left: 28%;
`

const PopupButtonThird = styled(PopupButton)`
  position: absolute;
  top: 61%;
  left: 18%;
`

const PopupButtonForth = styled(PopupButton)`
  position: absolute;
  top: 83%;
  left: 55%;
`

const PopupButtonFifth = styled(PopupButton)`
  position: absolute;
  top: 44%;
  left: 52%;
`

const PopupButtonSixth = styled(PopupButton)`
  position: absolute;
  top: 17%;
  left: 46%;
`

const PopupButtonSeventh = styled(PopupButton)`
  position: absolute;
  top: 7%;
  left: 72%;
`

const PopupButtonEight = styled(PopupButton)`
  position: absolute;
  top: 50%;
  left: 13%;
`

const PopupButtonNineth = styled(PopupButton)`
  position: absolute;
  top: 52%;
  left: 45%;
`

const PopupButtonTenth = styled(PopupButton)`
  position: absolute;
  top: 65%;
  left: 82%;
`

const PopupButtonEleventh = styled(PopupButton)`
  position: absolute;
  top: 76%;
  left: 75%;
`

const CatYear = ({ data }) => {
  return (
    <Layout>
      <SEO title={'Můj první rok'} lang='cse' />
      <Section>
        <HeroSectionWrapper>
          <HeroSectionImage>
            <Image fluid={data.allFile.nodes[4].childImageSharp.fluid} />
          </HeroSectionImage>
          <HeroSectionContent>
            <HeroSectionContentIcons>
              <Image fluid={data.allFile.nodes[5].childImageSharp.fluid} />
            </HeroSectionContentIcons>
            <HeroSectionContentTitle>
              MŮJ PRVNÍ ROK
              <span>Aneb průvodce pro štěněčí rodiče</span>
            </HeroSectionContentTitle>
            <HeroSectionContentButtons>
              <HeroButton href={'#start'}>Začít</HeroButton>
              <HeroButtonRounded as={Link} to={'/prvni-rok-pes'}>
                <span />
                Mám štěně
              </HeroButtonRounded>
            </HeroSectionContentButtons>
          </HeroSectionContent>
        </HeroSectionWrapper>
      </Section>
      <BlueSection id={'start'}>
        <Container>
          <h4>Jedno kotě nebo raději dvě?</h4>
          <p>
            <strong>
              Záleží na tom, kolik času jste schopni a ochotni své kočce
              věnovat.
            </strong>
            Máte-li rodinu, ve které je téměř pořád někdo doma nebo alespoň
            malého pejska, kočka může stále někoho pozorovat. Má pocit, že se
            neustále něco děje a nenudí se. V takovém případě se nemusíte bát,
            že by jí kočičí společnost chyběla.
          </p>
          <p>
            <strong>Pokud jste ale časově velmi vytížení</strong> a kočku chcete
            tak trochu i proto, aby na Vás doma někdo čekal a vítal Vás, pak je
            lepší pořídit kočky rovnou dvě. Rozdíl v péči o jednu či dvě kočky
            je minimální. Kočky jsou společenská zvířata a přílišná samota jim
            nesvědčí. Ideální je pořídit dva sourozence, kteří se dokonale
            znají, a tak si i snáze zvyknou na nové prostředí. Druhá možnost
            jsou dvě přibližně stejně stará koťátka, která se spolu lépe a dříve
            sehrají než kotě a starší kočka. Odměnou Vám budou téměř cirkusová
            představení, kterých jsou koťata, ale i dospělé kočky schopné.
          </p>
          <h5>Kočku nebo kocoura?</h5>
          <p>
            Pokud se nehodláte věnovat dalšímu chovu a koťátko chcete jako
            společníka, pak bude nejvíce záležet na tom, se kterým kotětem si
            padnete do oka. Všeobecně platí, že kocouři bývají zvědavější a také
            větší šašci. Kočky jsou naopak celkově aktivnější a velice rády
            „užitečné“ – zcela jistě Vám nejednou budou asistovat při vaření.
            Ale i zde existují výjimky, které předchozí tvrzení zcela popřou.
            Velmi záleží na povaze a zázemí, ve kterém koťátka vyrůstala.
          </p>
        </Container>
        <HeartImage>
          <svg
            width='453'
            height='609'
            viewBox='0 0 453 609'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M328.058 609C319.932 609 312.33 605.374 306.956 599.288L51.3778 304.565C17.1696 270.508 0 225.963 0 181.677C0 136.484 17.956 91.0327 53.4749 56.8469C88.4695 23.0495 134.343 6.08612 179.822 6.08612C225.958 6.08612 272.355 23.438 307.481 58.0123L325.961 76.1412L352.305 50.7608C387.431 16.9634 433.173 0 478.784 0C524.919 0 571.316 17.3519 606.442 51.9262C643.14 88.0544 659.13 135.966 658.999 182.195C658.999 224.927 645.893 266.883 621.645 300.809L619.024 303.917L570.268 354.419L349.291 599.677C343.917 605.633 336.315 609 328.32 609H328.058ZM92.7946 96.6009C68.4164 120.168 56.3583 150.858 56.2273 181.807C56.2273 212.237 68.0232 242.538 91.8772 266.106L93.45 267.66L328.844 538.945L528.72 317.125L529.375 316.478L577.083 267.012C593.335 243.704 603.034 212.755 602.903 182.195C602.903 148.139 591.369 115.507 566.86 91.2917C542.613 67.4652 510.895 55.552 478.915 55.552C447.328 55.552 416.003 67.2063 391.887 90.5148L345.621 135.06C334.611 145.678 317.048 145.549 306.17 134.801L268.03 97.3778C243.783 73.5514 212.065 61.6381 179.953 61.6381C148.367 61.6381 117.042 73.2924 92.9257 96.4714'
              fill='white'
              fillOpacity='0.15'
            />
          </svg>
        </HeartImage>
      </BlueSection>
      <Section>
        <ContainerSmall>
          <TextCenter>
            <h2>PÁR PRAKTICKÝCH RAD</h2>
            <p>
              <strong>Většina koťátek přebírá své návyky od matky.</strong>
            </p>
            <p>
              Je tedy ideální, pokud s ní tedy stráví dostatek času. Čím dříve
              naučíte Vaše koťátko na pravidelnou péči, tím lépe. Doporučujeme
              formu hry a odměny – nechá se učesat, dostane pamlsek. Takto
              nenásilně přistupujte ke všem úkonům, které budete s koťátkem
              provádět.
            </p>
            <p>
              Ovšem i zde platí pravidlo, že nic se nemá přehánět a preventivní
              kontrola stačí jednou za měsíc. A co je při péči o kotě dobré
              pravidelně sledovat?
            </p>
          </TextCenter>
        </ContainerSmall>
      </Section>
      <section>
        <DotWrapper>
          <FirstImageWrapper>
            <Image fluid={data.allFile.nodes[0].childImageSharp.fluid} />
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonFirst color={'#EEC22A'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#EEC22A'
                    />
                  </svg>
                  <span>Uši</span>
                </PopupButtonFirst>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#EEC22A'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>Uši</h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    Pokud je kočka zdravá, není nutné uším (stejně jako očím)
                    věnovat přehnanou péči. I při čištění uší je ideální
                    bavlněný ručník, který nepouští chlupy. Ručník navlhčete a
                    ucho opatrně zevnitř ven omyjte. Čištění Vám půjde lépe,
                    když si druhou rukou ucho přidržíte. Opakujte čištění
                    víckrát vždy s čistým ručníkem, abyste do ucha nezanesli
                    žádná cizí tělesa. Dbejte na to, abyste kočku neporanili
                    nebo ji nezpůsobili bolest. Nikdy nepoužívejte papírový
                    kapesník k proniknutí hlouběji do ucha - tím byste mohli
                    kočce spíš ublížit. Při ušním výtoku nebo černém mazu
                    doporučujeme návštěvu veterináře.
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonSecond color={'#7CB227'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#7CB227'
                    />
                  </svg>
                  <span>Oči</span>
                </PopupButtonSecond>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#7CB227'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>Oči</h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    Z očí by nemělo téct nebo být zalepené od ospalků. Měly by
                    být jasné a čisté. Na preventivní čištění očí je vhodné
                    použít navlhčený ručník, nejlépe bavlněný. Ručník navlhčete
                    v převařené vodě a kočce jemně omyjte oči, okolní srst a
                    případně víčka. Nikdy nezasahujte přímo do oka, mohli byste
                    ho poranit. Používejte vždy pouze čistý ručník, abyste
                    zabránili infekci nebo zanesení cizích těles do oka. Pokud
                    má kočka po delší dobu přes oko přetažené třetí víčko,
                    doručujeme návštěvu veterináře.
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonThird color={'#EEC22A'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#EEC22A'
                    />
                  </svg>
                  <span>Stříhání drápků</span>
                </PopupButtonThird>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#EEC22A'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>
                    Stříhání drápků
                  </h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    Na drápky používejte pouze speciální kleštičky. Pokud se
                    kotě stříhání brání a máte obavu, že byste ho mohli nechtěně
                    poranit. Přenechte úkon odborníkovi a objednejte se u
                    veterináře. Stříhání ale není tak často nutné. Kočky si
                    většinou drápky samy brousí a tím i zkracují pomocí
                    škrabacích hraček nebo třeba stromů.
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonForth color={'#ADD4E1'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#ADD4E1'
                    />
                  </svg>
                  <span>Mytí srsti</span>
                </PopupButtonForth>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#ADD4E1'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>Mytí srsti</h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    Kočky ve většině případů nemají vodu rády. Pokud to tedy
                    není nezbytně nutné, volíme pro péči o kožíšek jemnější a
                    pro ně přijatelnější metodu tzv. suchého koupání pomocí
                    speciálních pudrů. Jiné je to u dlouhosrstých nebo
                    polodlouhosrstých výstavních koček. Zde je naopak koupel
                    nezbytná. Používejte pouze speciální kočičí kosmetické
                    přípravky, které vám poradí váš chovatel. Nikdy nepoužívejte
                    mýdla a šampony určené pro lidi.
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
          </FirstImageWrapper>
        </DotWrapper>
        <DotWrapper>
          <SecondImageWrapper>
            <Image fluid={data.allFile.nodes[1].childImageSharp.fluid} />
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonFifth color={'#EEC22A'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#EEC22A'
                    />
                  </svg>
                  <span>Česání srsti</span>
                </PopupButtonFifth>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#EEC22A'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>
                    Česání srsti
                  </h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    Srst česejte a kartáčujte pravidelně podle náročnosti
                    plemene. Ideálně 1-2x týdně. V době línání vyčesávejte srst
                    častěji. Počítejte s tím, že by si kočka na česání měla
                    zvykat od mala a někdy to může trvat. Některé kočky nemají
                    česání rády celý život.
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
          </SecondImageWrapper>
        </DotWrapper>
        <DotWrapper>
          <ThirdImageWrapper>
            <Image fluid={data.allFile.nodes[2].childImageSharp.fluid} />
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonSixth color={'#EEC22A'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#EEC22A'
                    />
                  </svg>
                  <span>Bříško</span>
                </PopupButtonSixth>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#EEC22A'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>Bříško</h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    Pokud si před vámi kočka sama lehne na záda, zkuste ji na
                    bříšku podrbat. Pokud si nechá hladit bříško, je to známka
                    obrovské důvěry, protože jde o její nejcitlivější a
                    nezranitelnější místo. Je to největší projev náklonosti a
                    přátelství.
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonSeventh color={'#7CB227'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#7CB227'
                    />
                  </svg>
                  <span>Hlavička</span>
                </PopupButtonSeventh>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#7CB227'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>Hlavička</h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    Na bradičce, čelisti a u fousků po stranách tlamičky mají
                    škrabkání nejraději. Pokud je kočka agresivní, můžete ji
                    zkusit vzít do náruče a tato místa intenzivně třít. Uvidíte,
                    že se brzo uklidní. Navíc tu mají značkovací receptory,
                    takže tím jdete naproti tomu, abyste byli „jejím člověkem“
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonEight color={'#B48C51'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#B48C51'
                    />
                  </svg>
                  <span>Zadeček</span>
                </PopupButtonEight>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#B48C51'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>Zadeček</h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    Stejně jako psi, i kočky milují drbání u kořene ocásku.
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonNineth color={'#7CB227'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#7CB227'
                    />
                  </svg>
                  <span>Hlazení</span>
                </PopupButtonNineth>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#7CB227'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>Hlazení</h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    Hlazením se prohlubuje Váš vztah a vzájemná důvěra. Většina
                    koček hlazení miluje a až se lépe poznáte, koťátko Vám bude
                    umět dát jasně najevo, kde je mu nejvíce příjemné. Nikdy
                    kočku do hlazení nenuťte násilím, jsou to nezávislé
                    osobnosti s vlastním názorem.
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonTenth color={'#B48C51'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#B48C51'
                    />
                  </svg>
                  <span>Kontrola zoubků</span>
                </PopupButtonTenth>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#B48C51'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>
                    Kontrola zoubků
                  </h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    K pravidelné hygieně patří i čištění zoubků, které lze
                    provádět speciálním kartáčkem na prstu. Existují ale i různé
                    hračky na čištění zubů. Kočky vyloženě milují hračky z
                    větviček z aktinidie stříbrné nebo matatabi.
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonEleventh color={'#ADD4E1'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#ADD4E1'
                    />
                  </svg>
                  <span>Náladovost</span>
                </PopupButtonEleventh>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#ADD4E1'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>Náladovost</h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    Kočka dokáže svou náladu dávat najevo i ocasem a jsou díky
                    němu lépe čitelné. Když s ním začnou divoce mávat ze strany
                    na stranu, raději se kliďte. Znamená to, že jsou nervózní a
                    v hlavě jim běží únikový plán.
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
          </ThirdImageWrapper>
        </DotWrapper>
      </section>
      <GreenSection>
        <Container>
          <h2>Kastrace</h2>
          <p>
            <strong>
              Pokud nechcete založit chovatelskou stanici, pak nezáleží, zda si
              pořídíte běžné kotě nebo kotě s PP (průkaz původu). Kastrací
              zajistíte lepší životní podmínky nejen vaší kočce, ale i sobě.
              Většina chovatelů ušlechtilých koček dává do nových rodin koťátka
              již kastrovaná.
            </strong>
          </p>
          <p>
            <strong>Kastrace koček má několik výhod.</strong> Kastrované kočky
            jsou méně agresivní, důvěřivější a jejich teritorium je menší, což
            snižuje riziko nehod a bojů s jinými kočkami. Kočky nemrouskají a
            nehrozí rizika zánětu dělohy. Kocouři zase neznačkují v bytě své
            území. Obě pohlaví se zbytečně nevysilují, nehubnou a neztrácí
            kondici. Kastrované kočky žijí v průměru déle než nekastrované, jsou
            vyrovnanější a méně podléhají stresu. Kastrace v neposlední řadě
            zabraňuje nežádoucí reprodukci koček.
          </p>
        </Container>
      </GreenSection>
      <Image fluid={data.allFile.nodes[3].childImageSharp.fluid} />
    </Layout>
  )
}

export const query = graphql`
  query imagesCatYear {
    allFile(
      filter: { dir: { regex: "/(year-kocka)/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          fixed {
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
            ...GatsbyImageSharpFixed
          }
          fluid(maxWidth: 2000) {
            src
            srcSet
            srcSetWebp
            srcWebp
            sizes
            base64
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

export default CatYear
